
































































import { Models } from '@mtap-smartcity/api';
import { dateTimeToStr } from '@/utils/time_format';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { capitalize } from '@/utils/string_format';
import { namespace } from 'vuex-class';
import { TelemetryAction, TelemetryActionType } from '@/store/modules/telemetry/types';
import {
  DevicesAction, DevicesActionType, DevicesGetter, DevicesGetterType
} from '@/store/modules/devices/types';
import { AppAction, AppActionType, DrawerTab } from '@/store/modules/app/types';

const app = namespace('app');
const devices = namespace('devices');
const telemetry = namespace('telemetry');

@Component
export default class NotificationsTable extends Vue {
  @Prop({ required: true, type: Array }) notifications!: Array<
  Models.Telemetries.LampMtap1V1Telemetry
  | Models.Telemetries.LampMtap2V1Telemetry
  | Models.Telemetries.LampVe1V1Telemetry
  | Models.Telemetries.LampVe2V1Telemetry
  >;

  @Prop({ default: false, type: Boolean }) pending!: boolean;

  @app.Action(AppAction.SetSelectedTab)
  setSelectedTab!: AppActionType['SET_SELECTED_TAB'];

  @devices.Getter(DevicesGetter.GetDeviceByObjectId)
  getDeviceByObjectId!: DevicesGetterType['GET_DEVICE_BY_OBJECT_ID'];

  @devices.Action(DevicesAction.SetSelectedId)
  setSelectedId!: DevicesActionType['SET_SELECTED_ID'];

  @telemetry.Action(TelemetryAction.SetSelectedReading)
  setSelectedReading!: TelemetryActionType['SET_SELECTED_READING'];

  reading: any = {};

  showMoreCardStyle = {
    position: 'fixed', top: '0', left: '0', zIndex: 11
  };

  showMoreCard = false;

  get headers() {
    return [
      {
        text: '',
        value: 'icon',
        class: 'sticky-header',
      },
      {
        text: capitalize(this.$tc('main.device')),
        value: 'device',
        class: 'sticky-header',
      },
      {
        text: capitalize(this.$tc('main.error')),
        value: 'errorMessage',
        class: 'sticky-header',
      },
      {
        text: capitalize(this.$t('main.time') as string),
        value: 'timestamp',
        class: 'sticky-header',
      },
    ];
  }

  get items() {
    return this.notifications
      .map((n) => ({
        id: n.id,
        type: n.telemetry.status === 0 ? 'notification' : 'error',
        icon: n.telemetry.status === 0 ? 'mdi-message' : 'mdi-alert',
        device: n.object_id,
        errorMessage: n.telemetry.status === 0 ? '' : this.$t(`deviceErrors[${n.telemetry.status}]`),
        timestamp: dateTimeToStr(n.timestamp)
      }));
  }

  get readingStatusPositive() {
    if (!this.reading.telemetry) return null;
    return this.reading.telemetry.status === 0;
  }

  hideCard() {
    if (!this.showMoreCard) return;
    this.showMoreCard = false;
    this.reading = {};
    this.clearEvents();
  }

  showEvent(item) {
    this.clearEvents();
    if (!this.showMoreCard) this.showMoreCard = true;
    if (this.reading.id === item.id) {
      this.hideCard();
      return;
    }
    const notification = this.notifications.find((n) => n.id === item.id);
    this.reading = notification;
    if (notification && this.readingStatusPositive) {
      const deviceId = this.getDeviceByObjectId(notification.object_id);
      if (deviceId) {
        this.setSelectedId(deviceId.id!);
        this.setSelectedReading({ reading: notification });
        this.setSelectedTab(DrawerTab.Analytics);
        return;
      }
    }
    const showMoreActivator = this.$refs[
      `show-more-icon-${item.id}_${item.timestamp}`
    ];
    // @ts-ignore
    const { top, left } = showMoreActivator!.getBoundingClientRect();
    this.showMoreCardStyle = {
      position: 'fixed',
      top: `${top - 123}px`,
      left: `${left + 13}px`,
      zIndex: 11
    };
    window.onkeydown = this.handleKeyEsc;
    window.onmouseup = this.handleClick;
  }

  handleClick(e) {
    const showIconClicked = e.target.className === 'v-icon notranslate v-icon--link mdi mdi-information-outline theme--light';
    if (!this.showMoreCard || showIconClicked) return;
    this.hideCard();
  }

  // eslint-disable-next-line consistent-return
  handleKeyEsc(event: KeyboardEvent): void {
    if (!this.showMoreCard) return this.clearEvents();
    if (event.key !== 'Escape') {
      return this.hideCard();
    }
  }

  clearEvents() {
    window.onkeydown = null;
    window.onmouseup = null;
  }

  beforeDestroy() {
    this.clearEvents();
  }
}

