






















import {
  Component, Vue, Ref, Watch
} from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import NotificationsTable from './NotificationsTable.vue';
import { Models } from '@mtap-smartcity/api';
import { isLamp, isSensorTelemetry } from '@/utils/type_check';
import { DevicesState } from '@/store/modules/devices/types';
import { TelemetryAction, TelemetryActionType, TelemetryState } from '@/store/modules/telemetry/types';

enum ReportsTabs {
  Errors = 'errors',
  Notifications = 'notifications'
}

const devices = namespace('devices');
const telemetry = namespace('telemetry');

@Component({
  components: {
    NotificationsTable
  }
})
/**
 * @group Reports Card
 * Reports tab content
 */
export default class ReportsCard extends Vue {
  @Ref() scrollList!: HTMLDivElement

  pending = false;

  selectedTab: ReportsTabs = ReportsTabs.Errors

  recordsOffset = 0;

  recordsLimit = 20;

  tabs = [
    { name: this.$tc('main.error', 2), value: ReportsTabs.Errors },
    { name: this.$tc('main.notification', 2), value: ReportsTabs.Notifications },
  ]

  telemetryData: Array<Models.Telemetries.Telemetry> = [];

  selectedDeviceId: string | null = null;

  @devices.State
  devices!: DevicesState['devices']

  @telemetry.State
  telemetries!: TelemetryState['telemetries']

  @telemetry.Action(TelemetryAction.FetchDeviceTelemetriesArray)
  fetchDeviceTelemetriesArray!: TelemetryActionType['FETCH_DEVICE_TELEMETRIES_ARRAY'];

  get selectOptions() {
    const availableDevices = this.devices.filter(isLamp)
      .map((d) => ({
        text: d.object_id,
        value: d.object_id
      }));
    availableDevices.unshift({
      text: '-',
      value: null as any
    });
    return availableDevices;
  }

  get notifications() {
    return this.telemetryData.filter((t) => !isSensorTelemetry(t));
  }

  @Watch('selectedDeviceId')
  onSelectedDeviceIdChange() {
    this.recordsOffset = 0;
    this.telemetryData = [];
    this.fetchTelemetries();
  }

  @Watch('selectedTab')
  onSelectedTabChange() {
    this.recordsOffset = 0;
    this.telemetryData = [];
    this.fetchTelemetries();
  }

  fetchTelemetries() {
    if (!this.pending) {
      this.pending = true;
      const queryParams = {
        limit: this.recordsLimit,
        offset: this.recordsOffset,
        deviceId: this.selectedDeviceId,
        error: this.selectedTab === ReportsTabs.Errors ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11] : undefined
      };
      this.fetchDeviceTelemetriesArray(queryParams)
        .then(() => {
          this.telemetryData = this.telemetryData.concat(this.telemetries);
        })
        .finally(() => {
          this.pending = false;
        });
    }
  }

  onScroll() {
    const { scrollTop, clientHeight, scrollHeight } = this.scrollList;
    if (scrollHeight - clientHeight - scrollTop <= 0) {
      this.recordsOffset += this.recordsLimit;
      this.fetchTelemetries();
    }
  }

  mounted() {
    this.fetchTelemetries();
    this.scrollList.addEventListener('scroll', this.onScroll);
  }

  beforeDestroy() {
    this.scrollList.removeEventListener('scroll', this.onScroll);
  }
}
